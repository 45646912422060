import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Greenhill Psychology's Privacy Policy" />
    <div className="mx-auto prose lg:prose-lg divide-y divide-gray-200">
      <article>
        <h1>Privacy Policy</h1>
        <section>
          <h2>1. How we use your personal data</h2>
          <p>We take the privacy rights of all our clients seriously and we adopt a high standard of compliance and confidentiality when dealing with your data. We want you to understand that this is a safe place for you to discuss your feelings and concerns and we operate in a highly confidential environment. This privacy notice sets out the details of how we collect and process your personal and sensitive data when using our services.</p>
          <p>The data we collect from you is as submitted by you on online contact forms, when you provide details over the phone or via email, the initial information forms, clinical questionnaires/measures completed as part of treatment or consultation and details discussed during consultation/treatment.</p>
          <p>We will use your sensitive personal data (that is the data you completed in the information form, clinical questionnaires/measures completed as part of treatment or consultation, as well details you supply via online forms, email, or over the telephone and during consultation/treatment appointments) for the purposes of providing our services to you or if we need to comply with a legal obligation. Our legal ground of processing this data is your explicit consent.</p>
          <p>We will use your non-sensitive personal data to (i) register you as a new client, (ii) manage payment, (iii) collect and recover monies owed to us (iv) to manage our relationship with you, (v) send you details of our goods and services.</p>
          <p>Our legal grounds for processing your data are in relation to points (i) to (iv) above are for performance of a contract with you and in relation to (iii) and (v) above, necessary for our legitimate interests to develop our products/services and grow our business and to recover monies owed.</p>
          <p>We will not share your details with third parties for marketing purposes except with your express consent.</p>
        </section>
        <section>
          <h2>2. Disclosure of your personal data</h2>
          <p>We may have to share your personal and sensitive data with (i) service providers who provide IT and system administration support including software providers for accounting, marketing and practice management solutions, (ii) professional advisors including other healthcare professionals, lawyers, accountants, bankers, auditors and insurers (iii) HMRC and other regulatory authorities (iv) third parties to whom we sell, transfer or merge parts of our business or our assets and/or (v) to other professionals for the purposes of discussing your treatment. For example, all clinical psychologists have to access regular clinical supervision as part of registration.</p>
          <p>We require all of these third parties to whom we transfer your data to respect the security of your personal data and to treat it in accordance with the law. They are only allowed to process your personal data on our instructions.</p>
        </section>
        <section>
          <h2>3. International transfers</h2>
          <p>Some of our third party providers are businesses outside of the EEA in countries which do not always offer the same levels of protection for your personal data. We do our best to ensure a similar degree of security by ensuring that contracts, code of conduct or certification are in place which give your personal data the same protection it has within Europe. If we are not able to do so, we will request your explicit consent to the transfer and you can withdraw this consent at any time.</p>
        </section>
        <section>
          <h2>4. Data security</h2>
          <p>We have put in place security measures to prevent your personal and sensitive data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We also limit access to your personal and sensitive data to those employees, agents, contractors and other third parties who have a business need to know such data.</p>
          <p>They will only process your personal and sensitive data on our instructions and are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breaches and will notify you and any applicable regulator where we are legally required to do so.</p>
          <p>In certain circumstances you can ask us to delete your data. See the section entitled 'your rights' below for more information.</p>
          <p>We may anonymise your personal and sensitive data (so that you can no longer be identified from such data) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
        </section>
        <section>
          <h2>5. Data retention</h2>
          <p>We will only keep your personal and sensitive data for as long as is necessary to fulfil the purposes for which we collected it. We may retain your data to satisfy any legal, accounting, or reporting requirements so for example we need to keep certain information about you for 6 years after you cease to be a client for tax purposes. At present, we retain your data for 7 years after you cease to be a client, as per professional guidelines for psychologists in private practice.</p>
          <p>You have the right to ask us to delete the personal and sensitive data we hold about you in certain circumstances. See section 6 below.</p>
        </section>
        <section>
          <h2>6. Your rights</h2>
          <p>You are able to exercise certain rights in relation to your personal and sensitive data that we process. These are set out in more detail at <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a></p>
          <p>In relation to a Subject Access Right request, you may request that we inform you of the data we hold about you and how we process it. We will not charge a fee for responding to this request unless your request is clearly unfounded, repetitive or excessive in which case we may charge a reasonable fee or decline to respond.</p>
          <p>We will, in most cases, reply within one month of the date of the request unless your request is complex or you have made a large number of requests in which case we will notify you of any delay and will in any event reply within 3 months.</p>
          <p>If you wish to make a Subject Access Request, please send the request to Dr Beverley Clack (Data Controller), Dacrelands Clinic, Aldren's Lane, Skerton, Lancaster LA1 2DU or email <a href="mailto:beverley@greenhillpsychology.co.uk">beverley@greenhillpsychology.co.uk</a> marked for the attention of the Data Controller.</p>
        </section>
        <section>
          <h2>7. Keeping your data up to date</h2>
          <p>We have a duty to keep your personal and sensitive data up to date and accurate so from time to time we will contact you to ask you to confirm that your personal data is still accurate and up to date.</p>
          <p>If there are any changes to your personal data (such as a change of address) please let us know as soon as possible by writing to or emailing the addresses set out in section 6 above.</p>
        </section>
        <section>
          <h2>8. Complaints</h2>
          <p>We are committed to protecting your personal data but if for some reason you are not happy with any aspect of how we collect and use your data, you have the right to complain to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (<a href="https://www.ico.org.uk" target="_blank" rel="noopener noreferrer">www.ico.org.uk</a>).</p>
          <p>We should be grateful if you would contact us first if you do have a complaint so that we can try to resolve it for you.</p>
        </section>
      </article>
    </div>
  </Layout>
)

export default PrivacyPage
